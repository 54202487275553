<template >
    <div v-if="data">
        <div class="flex flex-col">
            <Result :shared-data="data.response.result" />
        </div>

        <card class="mt-4">
            <modal-field
                label="Transport date"
                disabled
            >
                <input 
                    :value="data.request.transportDate"
                    type="text"
                    disabled
                />
            </modal-field>
            
            <modal-field
                v-if="data.request.pickupTimeEarliest !== 'null'"
                label="Earliest pickup"
                class="pl-4"
            >
                <input 
                    :value="data.request.pickupTimeEarliest"
                    type="text"
                    disabled
                />
            </modal-field>

            <modal-field
                v-if="data.request.pickupTimeLatest !== 'null'"
                label="Latest pickup"
                class="pl-4"
            >
                <input 
                    :value="data.request.pickupTimeLatest"
                    type="text"
                    disabled
                />
            </modal-field>
        </card>

        <div class="flex gap-4 mt-4">
            <card title="Pickup" class="w-1/2">
                <div class="flex gap-3 w-full">
                    <modal-field label="Zip code" class="w-full">
                        <input :value="data.request.fromZipCode" type="text" disabled />
                    </modal-field>

                    <modal-field label="City" class="w-full">
                        <input :value="data.request.fromCity" type="text" disabled />
                    </modal-field>

                    <modal-field label="Country" class="w-full">
                        <input :value="data.request.fromCountryCode" type="text" disabled />
                    </modal-field>
                </div>
            </card>

            <card title="Delivery" class="w-1/2">
                <div class="flex gap-3 w-full">
                    <modal-field label="Zip code" class="w-full">
                        <input :value="data.request.toZipCode" type="text" disabled />
                    </modal-field>

                    <modal-field label="City" class="w-full">
                        <input :value="data.request.toCity" type="text" disabled />
                    </modal-field>

                    <modal-field label="Country" class="w-full">
                        <input :value="data.request.toCountryCode" type="text" disabled />
                    </modal-field>  
                </div>
            </card>
        </div>

        <div class="flex gap-4 my-4">
            <card 
                v-for="item, index in data.request.goods"
                :key="index"
                :title="`Line ${index + 1}`" 
                class="w-full"
            >
                <div class="flex gap-3">
                    <modal-field v-if="item.units" label="Units" class="w-full">
                        <input :value="item.units" type="text" disabled />
                    </modal-field>

                    <modal-field v-if="item.volume" label="Volume" class="w-full">
                        <input :value="item.volume" type="text" disabled />
                    </modal-field>

                    <modal-field  v-if="item.weight" label="Weight" class="w-full">
                        <input :value="item.weight" type="text" disabled />
                    </modal-field>
                </div>
            </card>
        </div>
    </div>
</template>

<script>
import axios from "axios";

import Result from '../Result.vue';

import 'vue2-datepicker/index.css';

export default {
    name: 'PageSharedResult',
    components: { 
        Result,
    },

    data: () => ({
        data: null,
        countries: [],
    }),

    async created() {
        const result = await axios.get(`${this.$apiUrl.proxio.calculationShared}/${this.$route.params.id}`);

        this.data = result.data.data;
    }
}
</script>